import React from "react"
import {CheckCircleIcon} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"

import hero from "../images/hero.jpg"
import Address from "../components/address";

const features = [
    {
        name: 'kpf.beratung',
        description: 'Wir unterstützen Sie im Bereich der Provenienzforschung unter Berücksichtigung der verschiedenen Entzugskontexte.',
    },
    {
        name: 'kpf.vernetzung',
        description: 'Wir verbinden Sie mit Expert:innen und Partner:innen im Bereich Provenienzforschung.',
    },
    {
        name: 'kpf.forschung',
        description: 'Wir bündeln und kommunizieren Forschungsergebnisse und -desiderate.',
    },
    {
        name: 'kpf.kontakt',
        description: 'Wir sind Ihre zentrale Kontaktstelle für Fragen und Anliegen für Provenienzforschung in Nordrhein-Westfalen.',
    },
]

const IndexPage = () => (
    <Layout>
        <Seo title="KPF.NRW"/>
        <div className="bg-white py-16">
            <div className="lg:relative">
                <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                        <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              KPF.NRW
            </span>
                            <span
                                className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Koordinationsstelle für Provenienzforschung in Nordrhein-Westfalen
            </span>
                        </h1>
                        <p className="mt-8 text-center text-xl text-gray-900 leading-8">
                            Für eine nachhaltige Forschungsinfrastruktur und transparente Kommunikation der
                            Provenienzforschung in NRW.
                        </p>
                        <div
                            className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                            <img
                                className="absolute inset-0 w-full h-full object-cover"
                                src={hero}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900">Unsere Mission</h2>
                </div>
                <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                            <dt>
                                <CheckCircleIcon className="absolute h-6 w-6 text-tefaf" aria-hidden="true"/>
                                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                            </dt>
                            <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            <div className="max-w-3xl mx-auto">
                <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl text-center">Kontakt</h2>
                <Address />
            </div>

        </div>

    </Layout>
)

export default IndexPage
